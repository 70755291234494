import { getErrorMsg } from '../../api/cartApi';

export const DIGITAL_CONTENT_ATTRIBUTE_IDS = [
  'digital_content_url',
  'digital_dredge_soundtrack_url',
  'digital_dredge_pale_reach_soundtrack_url',
  'digital_the_knight_witch_soundtrack_url',
  'digital_gord_soundtrack_url',
  'digital_dredge_soundtrack_url_wav',
  'digital_dredge_soundtrack_url_flac',
  'digital_dredge_pale_reach_soundtrack_url_wav',
  'digital_dredge_pale_reach_soundtrack_url_flac',
  'digital_dredge_iron_rig_soundtrack_url_wav',
  'digital_dredge_iron_rig_soundtrack_url_flac',
  'digital_dredge_iron_rig_soundtrack_url_mp3'
];

export const ERROR_MESSAGES = {
  5101: 'This coupon is already redeemed. Use another coupon.'
};

export const parseGamesResponse = (response) => {
  const games = response.items.filter((game) => {
    const isBonus = game.attributes.some((attr) => attr.external_id === 'Type' && attr.values.some((item) => item.value === 'Bonus'));

    return isBonus;
  });

  const bonuses = games.flatMap((game) => {
    const digitalContents = game.attributes.filter(
      (attr) => DIGITAL_CONTENT_ATTRIBUTE_IDS.includes(attr.external_id)
    );

    return digitalContents.map((digitalContent) => {
      const values = digitalContent.values.reduce((acc, item) => {
        acc[item.external_id] = item.value;
        return acc;
      }, {});

      return {
        url: values.url,
        name: values.name
      };
    });
  });

  return bonuses;
};

export const formatErrorMessage = (response) => {
  if (response.errorCode in ERROR_MESSAGES) {
    return ERROR_MESSAGES[response.errorCode];
  }

  return getErrorMsg(response);
};
