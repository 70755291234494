// скрипт из пакета прокидывает модуль сразу в window
import XsollaAnalytics from '@xsolla/metrika/watch';
import { ANALYTIC_COUNTER_ID, MERCHANT_ID } from '../config';

export const initAnalytics = () => {
  window.XA = new XsollaAnalytics({
    id: ANALYTIC_COUNTER_ID,
    siteDomains: ['store.team17.com'],
    server: 'https://datagather.xsolla.com/hit',
    merchantId: MERCHANT_ID
  });
};

export const sendCustomEvent = (name, exv_value = null) => {
  if (!window.XA || !name) return;
  window.XA.customEvent(name, {
    exv: exv_value
  });
};

export const sendClick = (name, value = null, exv_value = null) => {
  if (typeof window.XA !== 'object' || !name) {
    return;
  }
  window.XA.elementClick(name, {
    ev: value ? value.toString() : null,
    exv: exv_value
  });
};
